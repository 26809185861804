@font-face {
  font-family: 'Klein';
  src: url('../fonts/Klein/Klein-BlackItalic.woff2') format('woff2'),
    url('../fonts/Klein/Klein-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Klein Text';
  src: url('../fonts/Klein/KleinText-Light.woff2') format('woff2'),
    url('../fonts/Klein/KleinText-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Klein';
  src: url('../fonts/Klein/Klein-BoldItalic.woff2') format('woff2'),
    url('../fonts/Klein/Klein-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Klein Text';
  src: url('../fonts/Klein/KleinText-BlackItalic.woff2') format('woff2'),
    url('../fonts/Klein/KleinText-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Klein Text';
  src: url('../fonts/Klein/KleinText-Bold.woff2') format('woff2'),
    url('../fonts/Klein/KleinText-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Klein Condensed';
  src: url('../fonts/Klein/KleinCondensed-BoldItalic.woff2') format('woff2'),
    url('../fonts/Klein/KleinCondensed-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Klein';
  src: url('../fonts/Klein/Klein-Medium.woff2') format('woff2'),
    url('../fonts/Klein/Klein-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Klein';
  src: url('../fonts/Klein/Klein-ExtraLightItalic.woff2') format('woff2'),
    url('../fonts/Klein/Klein-ExtraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Klein';
  src: url('../fonts/Klein/Klein-ExtraBoldItalic.woff2') format('woff2'),
    url('../fonts/Klein/Klein-ExtraBoldItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Klein Text';
  src: url('../fonts/Klein/KleinText-ThinItalic.woff2') format('woff2'),
    url('../fonts/Klein/KleinText-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Klein';
  src: url('../fonts/Klein/Klein-ExtraLight.woff2') format('woff2'),
    url('../fonts/Klein/Klein-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Klein';
  src: url('../fonts/Klein/Klein-MediumItalic.woff2') format('woff2'),
    url('../fonts/Klein/Klein-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Klein Text Book';
  src: url('../fonts/Klein/KleinText-Book.woff2') format('woff2'),
    url('../fonts/Klein/KleinText-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Klein Text';
  src: url('../fonts/Klein/KleinText-Italic.woff2') format('woff2'),
    url('../fonts/Klein/KleinText-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Klein Condensed';
  src: url('../fonts/Klein/KleinCondensed-MediumItalic.woff2')
      format('woff2'),
    url('../fonts/Klein/KleinCondensed-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Klein Text';
  src: url('../fonts/Klein/KleinText-BoldItalic.woff2') format('woff2'),
    url('../fonts/Klein/KleinText-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Klein Condensed';
  src: url('../fonts/Klein/KleinCondensed-ThinItalic.woff2') format('woff2'),
    url('../fonts/Klein/KleinCondensed-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Klein Condensed Book';
  src: url('../fonts/Klein/KleinCondensed-Book.woff2') format('woff2'),
    url('../fonts/Klein/KleinCondensed-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Klein Condensed';
  src: url('../fonts/Klein/KleinCondensed-Medium.woff2') format('woff2'),
    url('../fonts/Klein/KleinCondensed-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Klein Text';
  src: url('../fonts/Klein/KleinText-ExtraLight.woff2') format('woff2'),
    url('../fonts/Klein/KleinText-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Klein';
  src: url('../fonts/Klein/Klein-Italic.woff2') format('woff2'),
    url('../fonts/Klein/Klein-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Klein Text Book';
  src: url('../fonts/Klein/KleinText-BookItalic.woff2') format('woff2'),
    url('../fonts/Klein/KleinText-BookItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Klein Condensed';
  src: url('../fonts/Klein/KleinCondensed-ExtraLight.woff2') format('woff2'),
    url('../fonts/Klein/KleinCondensed-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Klein';
  src: url('../fonts/Klein/Klein-Light.woff2') format('woff2'),
    url('../fonts/Klein/Klein-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Klein Condensed';
  src: url('../fonts/Klein/KleinCondensed-ExtraLightItalic.woff2')
      format('woff2'),
    url('../fonts/Klein/KleinCondensed-ExtraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Klein Condensed Book';
  src: url('../fonts/Klein/KleinCondensed-BookItalic.woff2') format('woff2'),
    url('../fonts/Klein/KleinCondensed-BookItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Klein Condensed';
  src: url('../fonts/Klein/KleinCondensed-ExtraBold.woff2') format('woff2'),
    url('../fonts/Klein/KleinCondensed-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Klein Text';
  src: url('../fonts/Klein/KleinText-ExtraBoldItalic.woff2') format('woff2'),
    url('../fonts/Klein/KleinText-ExtraBoldItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Klein Text';
  src: url('../fonts/Klein/KleinText-Thin.woff2') format('woff2'),
    url('../fonts/Klein/KleinText-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Klein Text';
  src: url('../fonts/Klein/KleinText-ExtraLightItalic.woff2') format('woff2'),
    url('../fonts/Klein/KleinText-ExtraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Klein Condensed';
  src: url('../fonts/Klein/KleinCondensed-Bold.woff2') format('woff2'),
    url('../fonts/Klein/KleinCondensed-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Klein Text';
  src: url('../fonts/Klein/KleinText-Black.woff2') format('woff2'),
    url('../fonts/Klein/KleinText-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Klein Book';
  src: url('../fonts/Klein/Klein-BookItalic.woff2') format('woff2'),
    url('../fonts/Klein/Klein-BookItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Klein';
  src: url('../fonts/Klein/Klein-ThinItalic.woff2') format('woff2'),
    url('../fonts/Klein/Klein-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Klein Condensed';
  src: url('../fonts/Klein/KleinCondensed-Black.woff2') format('woff2'),
    url('../fonts/Klein/KleinCondensed-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Klein Condensed';
  src: url('../fonts/Klein/KleinCondensed-Regular.woff2') format('woff2'),
    url('../fonts/Klein/KleinCondensed-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Klein Condensed';
  src: url('../fonts/Klein/KleinCondensed-ExtraBoldItalic.woff2')
      format('woff2'),
    url('../fonts/Klein/KleinCondensed-ExtraBoldItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Klein';
  src: url('../fonts/Klein/Klein-Thin.woff2') format('woff2'),
    url('../fonts/Klein/Klein-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Klein';
  src: url('../fonts/Klein/Klein-Regular.woff2') format('woff2'),
    url('../fonts/Klein/Klein-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Klein Condensed';
  src: url('../fonts/Klein/KleinCondensed-BlackItalic.woff2') format('woff2'),
    url('../fonts/Klein/KleinCondensed-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Klein Condensed';
  src: url('../fonts/Klein/KleinCondensed-Italic.woff2') format('woff2'),
    url('../fonts/Klein/KleinCondensed-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Klein Text';
  src: url('../fonts/Klein/KleinText-LightItalic.woff2') format('woff2'),
    url('../fonts/Klein/KleinText-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Klein Text';
  src: url('../fonts/Klein/KleinText-Medium.woff2') format('woff2'),
    url('../fonts/Klein/KleinText-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Klein Text';
  src: url('../fonts/Klein/KleinText-Regular.woff2') format('woff2'),
    url('../fonts/Klein/KleinText-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Klein Text';
  src: url('../fonts/Klein/KleinText-MediumItalic.woff2') format('woff2'),
    url('../fonts/Klein/KleinText-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Klein Condensed';
  src: url('../fonts/Klein/KleinCondensed-Light.woff2') format('woff2'),
    url('../fonts/Klein/KleinCondensed-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Klein Condensed';
  src: url('../fonts/Klein/KleinCondensed-LightItalic.woff2') format('woff2'),
    url('../fonts/Klein/KleinCondensed-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Klein Text';
  src: url('../fonts/Klein/KleinText-ExtraBold.woff2') format('woff2'),
    url('../fonts/Klein/KleinText-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Klein Condensed';
  src: url('../fonts/Klein/KleinCondensed-Thin.woff2') format('woff2'),
    url('../fonts/Klein/KleinCondensed-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Klein';
  src: url('../fonts/Klein/Klein-Bold.woff2') format('woff2'),
    url('../fonts/Klein/Klein-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Klein';
  src: url('../fonts/Klein/Klein-Black.woff2') format('woff2'),
    url('../fonts/Klein/Klein-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Klein';
  src: url('../fonts/Klein/Klein-LightItalic.woff2') format('woff2'),
    url('../fonts/Klein/Klein-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Klein';
  src: url('../fonts/Klein/Klein-ExtraBold.woff2') format('woff2'),
    url('../fonts/Klein/Klein-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Klein Book';
  src: url('../fonts/Klein/Klein-Book.woff2') format('woff2'),
    url('../fonts/Klein/Klein-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
