.navigation {
  width: 100%;
  height: 5rem;
  background-color: $color-primary;
  position: fixed;
  z-index: 2000;
  @include respond(tab-port) {
    width: 6rem;
    height: 100vh;
    right: 0;
  }
  &__checkbox {
    display: none;
  }

  &__background {
    width: 100%;
    height: 5rem;
    background-color: $color-primary;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.4s;
    @include respond(tab-port) {
      justify-content: flex-start;
      align-items: flex-start;
      padding: 120px 40px 0px 40px;
      width: 6rem;
      height: 100vh;
      right: 0;
    }
  }

  &__nav {
    z-index: 1500;
    opacity: 0;
    visibility: hidden;
    transition: all 0s;
    @include respond(tab-port) {
      right: 0;
    }
  }

  &__list {
    list-style: none;
    text-align: center;
    width: 100%;
    height: 100%;
    @include respond(tab-port) {
      position: relative;
      text-align: left;
    }
  }
  &__link {
    &:link,
    &:visited {
      font-family: 'Klein';
      font-weight: bold;
      font-style: normal;
      font-size: 2.9rem;
      color: $color-white;
      text-decoration: none;
      transition: all 0.3s;
      border-bottom: 0.2rem solid transparent;
      @include respond(tab-port) {
        display: inline-block;
        border-bottom: 0.3rem solid transparent;
        font-size: 3.6rem;
      }
    }
    &:hover,
    &:active {
      color: $color-black;
      border-bottom: 0.2rem solid $color-black;
      @include respond(tab-port) {
        width: 100%;
        border-bottom: 0.3rem solid $color-black;
      }
    }
  }
  &__paragraph {
    font-weight: 500;
    z-index: 1500;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    margin-bottom: 1rem;
    span {
      font-family: 'Klein';
      font-weight: 900;
    }
  }

  &__button {
    background-color: $color-primary;
    height: 3rem;
    width: 4rem;
    position: absolute;
    top: 1rem;
    left: 1rem;
    z-index: 2000;
    text-align: center;
    cursor: pointer;
    transition: all 0.4s;
    @include respond(tab-port) {
      left: -1rem;
    }
    @include respond(big-desktop) {
      left: 0.7rem;
    }
  }
  &__icon {
    position: relative;
    top: 1.5rem;
    &,
    &::before,
    &::after {
      width: 100%;
      height: 0.3rem;
      background-color: $color-white;
      display: inline-block;
    }
    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      transition: all 0.4s;
    }
    &::before {
      top: -0.8rem;
    }
    &::after {
      top: 0.8rem;
    }
  }

  &__logo {
    width: auto;
    height: 2.5rem;
    position: absolute;
    top: 1.3rem;
    right: 1rem;
    visibility: visible;
    @include respond(tab-port) {
      visibility: hidden;
    }
  }

  //FUNCTIONALITY
  &__checkbox:checked ~ &__background {
    height: 100%;
    padding-right: 0;
    @include respond(tab-port) {
      width: 40rem;
    }
  }
  &__checkbox:checked ~ &__background &__nav {
    opacity: 1;
    visibility: visible;
    transition: all 3s;
    width: 100%;
    @include respond(big-desktop) {
      transition: all 2s;
    }
  }
  &__checkbox:checked ~ &__button {
    @include respond(tab-port) {
      transform: translateX(-32rem);
    }
  }
  &__checkbox:checked ~ &__background &__paragraph {
    @include respond(tab-port) {
      text-align: left;
      padding-left: 40px;
    }
  }
  &__button:hover &__icon::before {
    top: -1rem;
  }

  &__button:hover &__icon::after {
    top: 1rem;
  }

  &__checkbox:checked + &__button &__icon {
    background-color: transparent;
  }

  &__checkbox:checked + &__button &__icon::before {
    top: 0;
    transform: rotate(135deg);
  }

  &__checkbox:checked + &__button &__icon::after {
    top: 0;
    transform: rotate(-135deg);
  }
}
