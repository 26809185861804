.slick-slider {
    position: relative;
    .slick-slide {
        display: flex;
    }
    .slick-arrow {
        width: 20px;
        height: 20px;
        border-top: 3px solid #ffffff;
        border-left: 3px solid #ffffff;
        z-index: 1;
        @include respond(tab-land){
            width: 35px;
            height: 35px;
            border-top: 5px solid #ffffff;
            border-left: 5px solid #ffffff;
        }
        &::before{
            content: "";
            font-family: none;
        }
    }
    .slick-prev{
        left: 5px;
        transform: rotate(-45deg);
        @include respond(tab-port){
            left: 80px;
        }
        @include respond(big-desktop){
            left: -70px;
        }
    }
    .slick-next{
        right: 5px;
        transform: rotate(140deg);
        @include respond(tab-port){
            right: 70px;
        }
        @include respond(big-desktop){
            right: -70px;
        }
    }
    ul.slick-dots {
        right: 0;
        left: 0;
        width: auto;
        bottom: 25px;
    }
    .slick-dots li.slick-active button:before {
        color: $color-white;
    }
    .slick-dots li button:before{
        font-size: 1.5rem;
        color: $color-white;
    }
}