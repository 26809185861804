@mixin absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin horizontalCentering {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

// MEDIA QUERY MANAGER
/*
0 - 576px:            PHONE
577px - 768px:          Tablet portrait
769px - 992px:         Tablet landscape
[992px - 1200px+] is where our normal styles apply
1800px +:             Big desktop

$breakpoint argument choices:
- phone
- tab-port
- tab-land
- big-desktop

1em = 16px
*/
@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media only screen and (min-width: 36em) {
      // 576
      @content;
    }
  }
  @if $breakpoint == tab-port {
    @media only screen and (min-width: 48em) {
      // 768
      @content;
    }
  }
  @if $breakpoint == tab-land {
    @media only screen and (min-width: 62em) {
      // 992
      @content;
    }
  }
  @if $breakpoint == big-desktop {
    @media only screen and (min-width: 75em) {
      //1200
      @content;
    }
  }
  @if $breakpoint == Extrabig-desktop {
    @media only screen and (min-width: 90em) {
      //1440
      @content;
    }
  }
}

// CSS effects
@mixin box-shadow($h-offset, $v-offset, $blur, $spread, $color) {
  -webkit-box-shadow: $h-offset $v-offset $blur $spread $color;
  -moz-box-shadow: $h-offset $v-offset $blur $spread $color;
  box-shadow: $h-offset $v-offset $blur $spread $color;
}

@mixin linear-gradient($fallbackColor, $direction, $colors...) {
  background: $fallbackColor;
  background: linear-gradient($direction, $colors);
}
