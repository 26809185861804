@import '../abstracts/abstracts';

/* BUTTONS */
/* SPECIAL STATE OF AN ANCHOR */
.btn {
  &,
  &:link,
  &:visited {
    padding: 1rem 2rem;
    display: inline-block;
    transition: transform 0.2s;
    font-size: 2.2rem;
    border: none;
    cursor: pointer;
    color: $color-quartenary;
  }
  &:hover,
  &:active {
    transform: translateY(-3px);
  }
  &__text {
    &:link,
    &:visited {
      background-color: $color-secondary;
      color: $color-white;
      text-transform: uppercase;
      text-decoration: none;
      padding: 0.5rem 1rem;
      font-size: 1.5rem;
      width: 90%;
      @include respond(tab-port) {
        padding: 1rem 2rem;
        font-size: 2.2rem;
        width: auto;
      }
    }
    &:hover,
    &:active {
      transform: translateY(-3px);
      color: $color-quartenary;
      @include respond(tab-port) {
        transform: translateX(-3px);
      }
    }
  }
  &__red {
    display: inline-block;
    margin: 20px 0;
    padding: 1rem 2rem;
    font-size: 2.2rem;
    background-color: $color-primary;
    color: $color-white;
    text-decoration: none;
    border: none;
    outline: none;
    cursor: pointer;
    transition: transform 0.2s;
    &:hover {
      transform: translateY(-3px);
    }
    &--selected{
      background-color: $color-buttons;
    }
  }
}

.contact-btn {
  display: inline-block;
  margin: 20px 0;
  padding: 10px;
  font-size: 1.5rem;
  background-color: $color-primary;
  color: $color-white;
  text-decoration: none;
}
