// COLORS
$color-primary: #ec1c24;
$color-secondary: #00afb4;
$color-tertiary: #eef1f4;
$color-quartenary: #005e70;
$color-white: #ffffff;
$color-buttons: #ff8c69;

// BLACK SCALE
$color-black: #000000;
$color-black-light-1: #181818;
$color-black-light-2: #313131;
$color-black-light-3: #212121;

$color-grey: #303030;
$color-grey-light: #6c6c6c;

$color-white: #ffffff;

//FONT

//LAYOUT
$content-max-width: 1140px;
