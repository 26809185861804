.footer {
  position: relative;
  max-width: $content-max-width;
  margin: auto;
  padding: 2.5rem 2.5rem 0 2.5rem;
  background: url(/img/footer-bg.jpg) no-repeat;
  background-position: center bottom;
  background-size: contain;
  background-color: $color-white;
  @include respond(phone) {
    display: flex;
    justify-content: space-between;
    padding: 2rem 6rem 6rem;
  }
  @include respond(tab-port) {
    height: 270px;
    padding-top: 4rem;
    padding-right: 15rem;
  }
  @include respond(tab-land) {
    height: 300px;
    padding-top: 4rem;
  }
  @include respond(big-desktop) {
    height: 370px;
    padding: 4rem 8rem 0 8rem;
  }

  &__message {
    width: 230px;
    line-height: 1.3;
    color: $color-primary;
    font-weight: bold;
    font-size: 3rem;
    @include respond(big-desktop) {
      width: 260px;
    }
  }
  &__left-column {
    height: 230px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 40px;
     @include respond(tab-port) { 
       height: auto;
       display: block;
     }
     .contact-btn {
       display: block;
       width: 100%;
       margin: 1rem 20%;
       height: 50px;
       text-align: center;
      @include respond(tab-land) {
        display: inline-block;
        width: auto;
        margin: 20px 10px 20px 0px;
      }
     }
    a {
      &.fa {
        float: right;
        width: 50px;
        height: 50.59px;
        margin-top: 1.6rem;
      }
    }
  }
  address {
    font-style: normal;
    line-height: 2.2;
    a,
    p {
      font-size: 1.6rem;
      font-family: 'Klein';
      font-weight: 500;
      color: $color-black-light-3;
      text-decoration: none;
    }
  }
  &__covid {
    text-align: center;
    padding: 4rem 0;
  }
}
