@import '../abstracts/abstracts';

/* BASIC RESET */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

/* BASIC SET UP */
html {
  font-size: 62.5%; //1 rem = 10px, 10px/16px = 62.5%

  @include respond(tab-port) {
    // width < 900?
    font-size: 50%; //1 rem = 8px, 8px/16px = 50%
  }
  @include respond(tab-land) {
    // width < 1200?
    font-size: 56.25%; //1 rem = 9px, 9px/16px = 56.25%
  }

  @include respond(big-desktop) {
    font-size: 75%; //1 rem = 12px, 12px/16px = 75%
  }
}

body {
  box-sizing: border-box;
  overflow-x: hidden;
  @include respond(tab-port) {
    padding-right: 6rem;
  }
  @include respond(Extrabig-desktop) {
    padding-right: 0rem;
  }
}

/**/

.container {
  width: 90%;
  margin: 0 auto;
  @include respond(tab-port) {
    max-width: $content-max-width;
  }
}

body.stop-transitions * {
  transition: none !important;
}

ul {
  list-style: none;
}
.fa {
  padding: 20px;
  font-size: 30px;
  width: 30px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  border-radius: 50%;
  color: white;
  display: inline !important;
}

.fa:hover {
  opacity: 0.7;
}

.fa-facebook {
  background: #3B5998;
}

.fa-linkedin {
  background: #007bb5;
}

.fa-instagram {
  background: #125688;
}
