@import "../abstracts/abstracts";

.form {
  &__group:not(:last-child) {
    margin-bottom: 2rem;
  }
  &__input {
    font-size: 2rem;
    font-family: inherit;
    padding: 1.5rem 2rem;
    color: $color-grey-light;
    background-color: $color-grey;
    border: none;
    border-bottom: 3px solid transparent;
    width: 100%;
    display: block;
    transition: all 0.3s;
    &:focus {
      outline: none;
      @include box-shadow(0, 1rem, 2rem, 0, rgba($color-black, 0.1));
      border-bottom: 3px solid $color-secondary;
    }

    &:focus:invalid {
      border-bottom: 3px solid $color-primary;
    }
  }
  textarea {
    resize: none;
  }
}
