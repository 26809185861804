@import "../abstracts/abstracts";

.job {
  display: table;
  width: 100%;
  margin-top: 2rem;
  @include respond(tab-port) {
    width: 90%;
  }
  @include respond(big-desktop) {
    width: 100%;
  }
  &__row {
    &:first-child {
      display: none;
      @include respond(tab-port) {
        display: table-row;
      }
    }
    display: table-row;
    &__content {
      background-color: $color-tertiary;
    }
  }
  &__cell {
    display: block;
    padding: 2rem 0 2rem 1.5rem;
    @include respond(tab-port) {
      padding: 1.5rem 0 1.5rem 1.5rem;
      display: table-cell;
    }
    &__content {
      padding: 1.5rem 0 1.5rem 1.5rem;
      &:not(:first-child) {
        border-bottom: 1rem solid $color-white;
      }
      @include respond(tab-port) {
        border-bottom: 1rem solid $color-white;
        padding: 0 0 0 1.5rem;
      }
      &:last-child {
        text-align: center;
        background-color: $color-white;
        padding: 1.5rem 0 4rem 0;
        border-bottom: none;
        @include respond(tab-port) {
          background-color: inherit;
          text-align: right;
          padding: 0;
          border-bottom: 1rem solid $color-white;
        }
      }
      span {
        display: block;
        font-weight: bold;
        text-decoration: none;
        font-size: 1.8rem;
        @include respond(tab-port) {
          display: none;
        }
      }
    }
  }
  &__title,
  &__sector {
    font-size: 1.8rem;
    text-transform: uppercase;
    font-weight: bold;
    &__content {
      font-weight: normal;
      text-transform: initial;
    }
  }
  &__title {
    &__content {
      text-decoration: underline;
    }
  }
}