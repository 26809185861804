@import '../abstracts/abstracts';

body {
  font-family: 'Klein', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.7;
}

.heading-primary {
  font-weight: 900;
  font-size: 2rem;
  text-transform: uppercase;
  border-bottom: 0.7rem solid;
  display: inline-block;
  z-index: 3;
  position: relative;
  @include respond(tab-port) {
    font-size: 2.5rem;
  }
  @include respond(big-desktop) {
    font-size: 3rem;
  }
}

.heading-secondary {
  font-size: 3rem;
  font-weight: bold;
  line-height: 1;
  @include respond(tab-port) {
    font-size: 5rem;
  }
  @include respond(big-desktop) {
    font-size: 7rem;
  }
  &--culture {
    font-size: 3.5rem;
    line-height: 1;
    font-weight: bold;
  }
}

.heading-tertiary {
  font-size: 1.8rem;
  font-weight: 900;

  @include respond(tab-port) {
    font-size: 2rem;
  }
  @include respond(big-desktop) {
    font-size: 3rem;
  }
  &--titles {
    font-size: 3rem;
    font-weight: bold;
    line-height: 1;
  }
  &--covid-titles {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1;
  }
}

.paragraph {
  font-size: 1.5rem;
  font-family: 'Klein Book';
  font-weight: normal;
  color: $color-black;
  line-height: 1.3;
  font-weight: 300;
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
  @include respond(big-desktop) {
    font-size: 1.8rem;
  }
  &__successful {
    font-size: 4rem;
    font-weight: bold;
    line-height: 1;
  }
  &__covid{
    text-transform: initial;
  }
}

.heading-primary,
.heading-secondary,
.heading-tertiary,
.heading-quarternary,
.paragraph {
  &--red {
    color: $color-primary;
  }
  &--light-blue {
    color: $color-secondary;
  }
  &--white {
    color: $color-white;
  }
}
