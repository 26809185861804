/* HEADER */
.header {
  background-color: $color-black;
  width: 100%;
  padding: 1.3rem 2rem;
  @include respond(phone) {
    padding: 1.3rem 3rem;
  }
  @include respond(tab-port) {
    padding: 2rem 5.1rem;
  }
  @include respond(tab-land) {
    padding: 2rem 5.4rem;
  }
  @include respond(big-desktop) {
    padding: 2rem 0;
  }
  .container {
    margin: 5rem 0 0 0;
    @include respond(tab-port) {
      margin-top: 0;
    }
    @include respond(big-desktop) {
      margin: 0px auto;
    }
  }
  &__img {
    max-width: 100%;
    height: auto;
  }
}

.aim {
  display: none;
}